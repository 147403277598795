import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Title from '../../components/title'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 4rem;
  h4 {
    margin-top: 4rem;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    b {
      font-family: Catamaran, sans-serif;
    }
  }
  table {
    width: 1000px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
  }
  td {
    font-family: Catamaran, sans-serif;
    padding: 0.25rem 0.5rem 0.25rem 0;
    a {
      font-family: Catamaran, sans-serif;
      text-decoration: none;
    }
    &.name {
      width: 200px;
    }
    &.date {
      width: 150px;
      text-align: right;
    }
    &.overflow {
      max-width: 500px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  th {
    font-family: Catamaran, sans-serif;
    padding: 0.25rem 0.5rem 0.25rem 0;
    text-align: left;
    &.date {
      text-align: right;
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  &:first-of-type {
    border: 1px solid red;
  }
`

const ArtistCategories = () => {

  const data = useStaticQuery(graphql`
    query {
      allSanityArtist(sort: {fields: [first_name, surname]}) {
        edges {
          node {
            credits {
              title
              category
              startdate
              enddate
            }
            id
            surname
            first_name
            _id
          }
        }
      }
    }
  `)

    const categories = [];
    const all_artists= data.allSanityArtist.edges;

    // get unique list of used categories;
    all_artists.map(a => {
      a.node.credits.map(c => {
        if(!categories.includes(c.category)){
          categories.push(c.category)
        }
      })
    })

    categories.sort();

    let count = 0;

    return (
      <Container>
        <Title noborder>Categories</Title>
        {
          categories.map((category, categoryindex) => {
              count = 0;
              return (
                <Row key={categoryindex}>

                <table>
                  <tbody>
                  <th>Artist Name</th><th>Credit Name</th><th className="date">Start Date</th><th className="date">End Date</th>

                  {all_artists.map((artist, artistindex) => {

                    return (
                      
                      artist.node.credits.map((credit, creditindex) => {

                      if(credit.category === category) {
                        count++;
                        return (
                          <tr key={creditindex}>
                            <td className="name"><a href={`https://pbj.sanity.studio/desk/artists;${artist.node._id}`}>{artist.node.first_name} {artist.node.surname}</a></td>
                            <td className="overflow">{credit.title}</td>
                            <td className="date">{credit.startdate}</td>
                            <td className="date">{credit.enddate}</td>
                          </tr>
                        )
                      }
                    })
                  )
                  })}
                </tbody>
                </table>
                <h4>{category} <b>{count}</b></h4>
                </Row>
              )
          })
        }
      </Container>
    )
  }

export default ArtistCategories
